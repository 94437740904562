import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const css = {
  wrapper: {
    display: "table"
  },
  icon: {
    display: "table-cell",
    marginRight: "5px",
    border: "1px solid #ececec",
    padding: "5px",
    color: "#e83021",
    fontSize: "1.5rem"
  },
  text: {
    display: "table-cell",
    verticalAlign: "middle",
    whiteSpace: "nowrap"
  },
  link: { textDecoration: "none", color: "inherit", display: "inline-block" }
};

class IconLink extends Component {
  render() {
    const { icon, text, link, style, color, border } = this.props;
    let iconCss = { ...css.icon };
    let linkCss = { ...css.link };
    if (color) {
      iconCss.color = color;
      linkCss.color = color;
    }

    if (border === false) {
      css.icon.border = "none";
      css.icon.padding = "5px 5px 5px 0px";
    }
    let target = "_blank";
    if (link && link.indexOf("https://") === -1) {
      target = "_self";
    }
    return (
      <a
        href={link}
        style={{ ...linkCss, ...style }}
        target={target}
        rel="noopener noreferrer"
      >
        <div style={css.wrapper}>
          <FontAwesomeIcon style={iconCss} icon={icon} />{" "}
          <div style={css.text}>{text}</div>
        </div>
      </a>
    );
  }
}

export default IconLink;
