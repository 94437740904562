import React, { Component } from "react";
import { Container, Row, Col } from "react-grid-system";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import {
  faPhone,
  faMapMarkerAlt,
  faBars,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import IconLink from "./IconLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const respConfig = {
  xs: [0, 576],
  sm: [576, 768],
  md: [768, 992],
  lg: [992, 1200],
  xl: [1200, 1400],
  xxl: [1400, 0]
};

const style = {
  container: {
    zIndex: "5"
  },
  logo: {
    maxWidth: "100%",
    maxHeight: "66px"
  },
  menu: {
    marginTop: "15px"
  },
  respMenuButton: {
    fontSize: "1.5rem",
    textAlign: "right",
    color: "#005b95"
  },
  respMenu: {
    position: "fixed",
    top: "0",
    left: "0",
    height: "100%",
    width: "100%",
    zIndex: "999",
    backgroundColor: "#005b95"
  },
  closeRespMenuButton: {
    textAlign: "right",
    padding: "15px",
    fontSize: "2.3rem",
    color: "rgba(255, 255, 255, 0.5)"
  },
  list: {
    margin: "30px 0px 0px 0px",
    textAlign: "right",
    padding: "0px",
    listStyle: "none",
    position: "relative",
    top: "2px"
  },
  listItem: {
    display: "inline-block",
    padding: "0px 15px",
    backgroundColor: "#005b95",
    border: "2px solid #005b95",
    color: "#fff"
  },
  listItemActive: {
    display: "inline-block",
    padding: "0px 15px",
    backgroundColor: "#fff",
    border: "2px solid #005b95",
    borderBottom: "2px solid #fff",
    color: "#005b95"
  },
  link: {
    padding: "10px 25px",
    textDecoration: "none",
    display: "block",
    color: "#fff"
  },
  linkActive: {
    padding: "10px 25px",
    textDecoration: "none",
    display: "block",
    color: "#005b95",
    position: "relative",
    top: "3px"
  },
  respList: {
    padding: "15px 0px",
    listStyle: "none",
    width: "100%"
  },
  respListItem: {
    padding: "20px 0px",
    textAlign: "center"
  },
  respLink: {
    color: "rgba(255, 255, 255, 0.5)",
    padding: "15px",
    textDecoration: "none"
  },
  respLinkActive: {
    color: "rgba(255, 255, 255, 1)",
    padding: "15px",
    textDecoration: "none"
  },
  header: {
    paddingTop: "10px"
  },
  headIconCols: {
    padding: "0px 15px 5px"
  },
  iconLink: {
    padding: "0px 25px"
  },
  iconLinkLast: {
    padding: "0px 0px 0px 25px"
  },
  dividerContainer: {
    padding: "0px"
  },
  divider: {
    height: "2px",
    backgroundColor: "#005b95"
  },
  table: {
    display: "table",
    height: "100%",
    float: "right"
  },
  alignBottom: {
    display: "table-cell",
    verticalAlign: "bottom"
  },
  rat: {
    display: "inline",
    position: "absolute",
    zIndex: 1,
    top: "130px",
    left: "5px"
  },
  masterLogo: {
    display: "inline",
    position: "absolute",
    width: "100px",
    left: "5px",
    zIndex: 1
  },
  masterLogoResp: {
    display: "inline",
    position: "absolute",
    width: "100px",
    left: "5px",
    zIndex: 1
  },
  ratResp: {
    display: "inline",
    position: "absolute",
    zIndex: 1,
    right: "5px"
  }
};

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowSize: this.getWindowSize(),
      menuOpen: false
    };
  }
  getWindowSize() {
    const width = window.innerWidth;
    return Object.keys(respConfig).reduce((size, testAgainst) => {
      const config = respConfig[testAgainst];
      return width >= config[0] && width <= config[1] ? testAgainst : size;
    }, "xxl");
  }
  updateWindowSize() {
    const size = this.getWindowSize();
    if (size !== this.state.windowSize)
      this.setState({ ...this.state, windowSize: size });
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateWindowSize.bind(this));
  }
  render() {
    const { windowSize, menuOpen } = this.state;
    const { links } = this.props;
    const currentPath = window.location.pathname;
    return (
      <header style={style.header}>
        {windowSize === "xxl" && (
          <img
            style={style.masterLogo}
            alt=""
            src="images/meisterbetrieb.png"
          />
        )}
        {windowSize === "xxl" && (
          <img style={style.rat} alt="" src="images/rat.png" />
        )}
        {windowSize !== "lg" &&
          windowSize !== "xl" &&
          windowSize !== "xxl" &&
          menuOpen && (
            <div style={style.respMenu}>
              <div style={style.closeRespMenuButton}>
                <img
                  style={style.masterLogoResp}
                  alt=""
                  src="images/meisterbetrieb.png"
                />
                <FontAwesomeIcon
                  onClick={e => {
                    e.preventDefault();
                    this.setState({ ...this.state, menuOpen: false });
                  }}
                  icon={faTimes}
                />
              </div>
              <ul style={style.respList}>
                {links.map((link, index) => {
                  const active = currentPath === link.path;
                  return (
                    <li
                      key={"navigation-resp-list-" + index}
                      style={style.respListItem}
                    >
                      <a
                        style={active ? style.respLinkActive : style.respLink}
                        href={link.path}
                      >
                        {link.name}
                      </a>
                    </li>
                  );
                })}
              </ul>
              <img style={style.ratResp} alt="" src="images/rat.png" />
            </div>
          )}
        {(windowSize === "lg" ||
          windowSize === "xl" ||
          windowSize === "xxl") && (
          <Container fluid={false}>
            <Row>
              <Col
                style={{ ...style.headIconCols, textAlign: "right" }}
                xs={12}
                sm={12}
                md={12}
                lg={12}
              >
                <IconLink
                  style={style.iconLink}
                  link="mailTo:office@johei.at"
                  icon={faEnvelope}
                  text="office@johei.at"
                />
                <IconLink
                  style={style.iconLink}
                  link="tel:+438946364"
                  icon={faPhone}
                  text="+43 894 63 64"
                />
                <IconLink
                  style={style.iconLinkLast}
                  link="https://www.google.com/maps/place/Johann+Heinisch/@48.1896108,16.3024821,15z/data=!4m5!3m4!1s0x0:0xdd66e242db14399d!8m2!3d48.1896108!4d16.3024821"
                  icon={faMapMarkerAlt}
                  text="Penzinger Str. 71/1, 1140 Wien"
                />
              </Col>
            </Row>
          </Container>
        )}
        <Container fluid={false}>
          <Row>
            <Col xs={12}>
              <Row>
                <Col xs={8} sm={8} lg={4}>
                  <img
                    style={style.logo}
                    alt="logo"
                    src="images/logoSmall.jpg"
                  />
                </Col>
                {windowSize !== "lg" &&
                  windowSize !== "xl" &&
                  windowSize !== "xxl" && (
                    <Col xs={4} sm={4} style={style.respMenuButton}>
                      <div
                        style={{
                          height: "100%",
                          width: "100%",
                          display: "table",
                          textAlign: "right"
                        }}
                      >
                        <div
                          style={{
                            display: "table-cell",
                            verticalAlign: "middle"
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faBars}
                            onClick={e => {
                              e.preventDefault();
                              this.setState({ ...this.state, menuOpen: true });
                            }}
                          />
                        </div>
                      </div>
                    </Col>
                  )}
                {(windowSize === "lg" ||
                  windowSize === "xl" ||
                  windowSize === "xxl") && (
                  <Col xs={12} sm={12} lg={8}>
                    <div style={style.table}>
                      <div style={style.alignBottom}>
                        <Row>
                          <Col style={style.container} xs={12}>
                            <div style={style.menu}>
                              <ul style={style.list}>
                                {links.map((link, index) => {
                                  const active = currentPath === link.path;
                                  return (
                                    <li
                                      style={
                                        active
                                          ? style.listItemActive
                                          : style.listItem
                                      }
                                      key={"navigation-list-" + index}
                                    >
                                      <a
                                        style={
                                          active ? style.linkActive : style.link
                                        }
                                        href={link.path}
                                      >
                                        {link.name}
                                      </a>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Container>
        <Container fluid={true} style={style.dividerContainer}>
          <div style={style.divider} />
        </Container>
      </header>
    );
  }
}

export default Navigation;
