import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const style = {
  headLine: {
    margin: "0px 0px 5px 0px",
  },
  text: {
    margin: "0px 0px 15px 0px"
  },
  icon: {
    display: "table-cell",
    verticalAlign: "top",
    fontSize: "3rem",
    padding: "0px 10px 0px 0px",
    minWidth: "60px",
    color: "#005b95"
  },
  primary: {
    color: "#e83021"
  },
  secondary: {
    color: "#005b95"
  }
};

class IconLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: false
    };
  }
  render() {
    const { icon, headLine, text } = this.props;
    return (
      <div style={{ display: "table" }}>
        <div style={style.icon}>
          <FontAwesomeIcon style={style.icon} icon={icon} />
        </div>
        <div style={{ display: "table-cell", verticalAlign: "top" }}>
          <h3 style={style.headLine}>{headLine}</h3>
          <p style={style.text}>{text}</p>
        </div>
      </div>
    );
  }
}

export default IconLink;
