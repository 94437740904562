import React, { Component } from "react";
import { Container, Row, Col } from "react-grid-system";

const style = {
  container: {
    zIndex: "0",
    padding: "0px"
  },
  image: {
    width: "100%"
  },
  block: {
    backgroundColor: "#fff",
    padding: "15px"
  },
  headLine: {
    margin: "0px 0px 15px 0px",
    fontSize: "2rem"
  },
  headLineSmall: {
    margin: "0px 0px 5px 0px",
    fontWeight: "300",
    fontSize: "1.25rem",
    color: "#616161"
  },
  primary: {
    color: "#e83021"
  },
  secondary: {
    color: "#005b95"
  }
};
class Home extends Component {
  render() {
    return (
      <div className="home-wrapper content-wrapper">
        <Container style={style.container} fluid={true}>
          <div className="content-wrapper">
            <img alt="" style={style.image} src="images/plumbing_home.jpg" />
          </div>
        </Container>
        <Container>
          <Row>
            <Col xs={12}>
              <div style={style.block} className="block">
                <h3 style={style.headLineSmall}>WILLKOMMEN BEI</h3>
                <h1 style={style.headLine}>
                  <span style={style.primary}>JOHANN</span>{" "}
                  <span style={style.secondary}>HEINISCH</span>
                </h1>
                <p>
                  Seit 1995 betreuen wir Privatkunden und Hausverwaltungen
                  erfolgreich mit unserem Know-How und technischen Ausführungen
                  auf höchstem Niveau.
                </p>
                <p>
                  Um einen Eindruck unserer Leistungen zu gewinnen, schauen Sie
                  sich doch einfach auf unserer Seite um.
                </p>
                <p>
                  Wir beraten Sie gerne und finden mit Ihnen gemeinsam die
                  perfekte Lösung für Ihren Bedarf. Kommen Sie doch zu uns!
                  Gerne kommen wir auch zu Ihnen!
                </p>
                <p>Oft braucht man schnelle Hilfe! </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Home;
