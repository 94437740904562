import React, { Component } from "react";
import { Container, Row, Col, Hidden } from "react-grid-system";
import {
  faFireAlt,
  faBath,
  faTools,
  faHandshake,
  faLeaf
} from "@fortawesome/free-solid-svg-icons";
import Service from "./../components/Service";

const style = {
  container: {
    paddingTop: "15px"
  },
  headLineSmall: {
    margin: "15px 0px 5px 0px",
    fontWeight: "300",
    fontSize: "1.25rem",
    color: "#616161"
  },
  text: {
    margin: "0px 0px 15px 0px"
  },
  service: {
    margin: "25px 0px 25px 0px"
  },
  image: {
    width: "100%"
  },
  primary: {
    color: "#e83021"
  },
  secondary: {
    color: "#005b95"
  }
};

class Contact extends Component {
  render() {
    return (
      <Container style={style.container}>
        <Row>
          <Col xs={12}>
            <h5 style={style.headLineSmall}>Service</h5>
            <p>
              Unsere Erfahrung erlaubt es uns Ihnen ein weit gefächertes Angebot
              zu bieten.
            </p>
          </Col>
        </Row>
        <Row>
          <Col xs={12} sm={12} md={12} lg={8}>
            <Row>
              <Col style={style.service} xs={12} sm={12}>
                <Service
                  headLine="Heizung"
                  text="Vom tropfenden Heinzungsventil, über den Austausch von
                      einem Heizkörper bis zum Installieren einer ganzen
                      Heizungsanlage für ein Einfamilienhaus. Uns ist kein
                      Problem zu klein oder zu groß!"
                  icon={faFireAlt}
                />
              </Col>
              <Col style={style.service} xs={12} sm={12}>
                <Service
                  headLine="Sanitär"
                  text=" Vom tropfenden Wasserhahn, über den Austausch von Armaturen und
              Keramik bis hin zur Installation Ihres Traumbades. Wir machen auch
              aus dem kleinsten Bad eine Wohlfühloase."
                  icon={faBath}
                />
              </Col>
              <Col style={style.service} xs={12} sm={12}>
                <Service
                  headLine="Wartung"
                  text=" Vergessen Sie nicht! Ihre Gasgeräte müssen jedes Jahr gewartet werden. Denn nur mit einem jährlichen Service leben sie länger und sind garantiert sicher."
                  icon={faTools}
                />
              </Col>
              <Col style={style.service} xs={12} sm={12}>
                <Service
                  headLine="Alternativ"
                  text="Der Umwelt zuliebe greifen immer mehr Menschen auf alternative Energien zurück. Wir kennen uns aus und beraten Sie gerne!"
                  icon={faLeaf}
                />
              </Col>
              <Col style={style.service} xs={12} sm={12}>
                <Service
                  headLine="Partner"
                  text=" Um professionell arbeiten zu können, haben wir nur Partner, auf die wir uns auch verlassen können. Daher wählen wir unseren Kontakt sorgfältig aus und bemühen uns stets um eine persönliche, gute Beziehung."
                  icon={faHandshake}
                />
              </Col>
            </Row>
          </Col>
          <Hidden xs sm md>
            <Col lg={4}>
              <img alt="" style={style.image} src="images/heater.png" />
            </Col>
          </Hidden>
        </Row>
      </Container>
    );
  }
}

export default Contact;
