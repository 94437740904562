import React, { Component } from "react";
import { Container, Row, Col } from "react-grid-system";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import {
  faMapMarkerAlt,
  faPhone,
  faFax
} from "@fortawesome/free-solid-svg-icons";
import IconLink from "./../components/IconLink";

const style = {
  container: {
    paddingTop: "15px"
  },
  headLineSmall: {
    margin: "15px 0px 5px 0px",
    fontWeight: "300",
    fontSize: "1.25rem",
    color: "#616161"
  },
  image: {
    width: "100%"
  },
  primary: {
    color: "#e83021"
  },
  secondary: {
    color: "#005b95"
  },
  data: { margin: "0px -15px 15px -15px" },
  tramListHeadline: {
    marginTop: "24px"
  },
  tramList: {
    paddingLeft: "15px"
  },
  tramListItem: {
    marginBottom: "15px"
  },
  map: {
    width: "100%",
    height: "350px",
    marginTop: "25px"
  },
  iconCols: {
    display: "flex",
    alignItems: "flex-end",
    paddingTop: "15px"
  }
};

class Contact extends Component {
  render() {
    return (
      <Container style={style.container}>
        <Row>
          <Col xs={12}>
            <h5 style={style.headLineSmall}>Kontakt</h5>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <p>
              Für schnelle Anfragen rufen Sie uns gleich direkt an oder senden
              Sie uns eine Email.
            </p>
          </Col>
        </Row>
        <Row style={style.data}>
          <Col xs={12} sm={6} lg={4}>
            <Row>
              <Col style={style.iconCols} xs={12}>
                <IconLink
                  link="mailTo:office@johei.at"
                  icon={faEnvelope}
                  text="office@johei.at"
                />
              </Col>
              <Col style={style.iconCols} xs={12}>
                <IconLink
                  link="tel:+438946364"
                  icon={faPhone}
                  text="+43 894 63 64"
                />
              </Col>
              <Col style={style.iconCols} xs={12}>
                <IconLink
                  link="fax:+438946364"
                  icon={faFax}
                  text="+43 894 63 646"
                />
              </Col>
              <Col style={style.iconCols} xs={12}>
                <div>
                  <IconLink
                    link="https://www.google.com/maps/place/Johann+Heinisch/@48.1896108,16.3024821,15z/data=!4m5!3m4!1s0x0:0xdd66e242db14399d!8m2!3d48.1896108!4d16.3024821"
                    icon={faMapMarkerAlt}
                    text="Penzinger Str. 71/1, 1140 Wien"
                  />
                </div>
              </Col>
            </Row>
          </Col>
          <Col xs={12} sm={6}>
            <p style={style.tramListHeadline}>
              Öffentliche Verkehrsmittel in unserer Nähe:
            </p>
            <ul style={style.tramList}>
              <li style={style.tramListItem}>
                Kennedybrücke Hietzing (<b>U4</b>, <b>10</b>, <b>58</b>,{" "}
                <b>60</b>, <b>51A</b>, <b>58B</b>, <b>56B</b>, <b>156B</b>,{" "}
                <b>260</b>)
              </li>
              <li style={style.tramListItem}>
                Penzing (<b>S45</b>)
              </li>
            </ul>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <iframe
              style={style.map}
              id="gmap_canvas"
              title="map"
              src="https://maps.google.com/maps?q=Johann%20Heinisch&t=&z=13&ie=UTF8&iwloc=&output=embed"
              frameBorder="0"
              scrolling="no"
              marginHeight="0"
              marginWidth="0"
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Contact;
