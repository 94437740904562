import React, { Component } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Preloader, Placeholder } from "react-preloading-screen";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Home from "./views/Home";
import Services from "./views/Services";
import Contact from "./views/Contact";
import Impressum from "./views/Impressum";
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
import "./style.css";

const pages = [
  { name: "Startseite", path: "/", component: Home },
  { name: "Services", path: "/services", component: Services },
  { name: "Kontakt", path: "/kontakt", component: Contact },
  { name: "Impressum", path: "/impressum", component: Impressum }
];

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWith: window.innerWidth
    };
  }

  render() {
    return (
      <BrowserRouter>
        <Preloader>
          <Placeholder>
            <div style={{ textAlign: "center" }}>
              <span
                style={{
                  color: "#005b95",
                  fontSize: "4rem"
                }}
              >
                <FontAwesomeIcon icon={faSpinner} spin />
              </span>
            </div>
          </Placeholder>
          <div className="App">
            <div className="wrapper-content">
              <Navigation links={pages} />
              <Switch>
                {pages.map((route, index) => {
                  return (
                    <Route
                      key={"route-" + index}
                      exact
                      path={route.path}
                      component={route.component}
                    />
                  );
                })}
              </Switch>
            </div>
            <Footer />
          </div>
        </Preloader>
      </BrowserRouter>
    );
  }
}

export default App;
